import type { Roof } from '@/project';
import type { Roof as RoofCalc } from '@/calculation/roof';
import { type ResultRow, type TotalTiles, Category } from '@/calculation/result';

import { watch } from 'vue';
import { storeToRefs } from 'pinia';
import _ from 'lodash';

// Calculations
import { addResults } from '@/calculation/result';
import {
  GavelTillval,
  GetRoofForm,
  GetRoofType,
  RoofTypeIndex,
  TileFamily,
  NockConnector,
  NockPannaExtra,
  RoofArticle,
  TileColor,
  MarketStr,
} from '@/calculation/common';
import { RoofPart } from '@/calculation/roofpart';

// stores
import pinia from '@/store/store';
import { useProjectStore } from '@/store/project';
import { useArticleStore } from '@/store/article';

import { BTSBaseRoof, Gable, SecuritySystem, BattenStep, NockBoard, ConcealedDraining, SealingAiring, SnowSlideObstacle } from '@/enums';
import { getRoofTile } from '@/helpers/tile';
import { SAFECalc } from '@/calculation/safecalc';
import { PWCalc } from '@/calculation/pwcalc';

const project = useProjectStore(pinia);

const { data, id, market } = storeToRefs(project);
const { setCalculatedAmounts, setLathMeasurement, getRoofCalc, getTileCalc, getRoofMeasurements, setCalculatedTileCount } = useProjectStore(pinia);
const { getArticlesByPrefix } = useArticleStore(pinia);

const adjustResults = async (results: ResultRow[], roof: RoofCalc): Promise<ResultRow[]> => {
  const newResults: ResultRow[] = [];

  // 1. Re-configure results into full and half pallets
  for (const result of results) {
    // BENT-180: Don't touch extra-rows
    if (result.category === Category.Tillval) {
      newResults.push(result);
      continue;
    }

    const artnr = result.artnr.replace(/\.5$/, '');
    const articles = await getArticlesByPrefix(artnr, false, { skip_halfpallet_filter: true });
    const article = articles[artnr];
    const halfpallet = articles[artnr + '.5'];
    for (const o in result.origins) {
      const amount = result.origins[o];
      let leftovers = amount % article?.unfilteredUnit['p'];
      if (article && article.unfilteredUnit['p'] > 0 && leftovers !== 0 && halfpallet) {
        // if we have leftovers, check if they are enough for a half pallet
        const perPallet = halfpallet.unfilteredUnit['p2'];
        if (halfpallet && leftovers >= perPallet) {
          const halfpallets = Math.floor(leftovers / perPallet);
          result.origins[o] -= leftovers;
          result.total -= leftovers;
          leftovers -= halfpallets * perPallet;

          if (result.total > 0) newResults.push(result); // Add original row, if qty is not 0
          roof.addArticleRowByArtnr(newResults, halfpallet.ArtNr, halfpallets * perPallet, result.category, o, RoofArticle.TakPannaHalfPallet); // Add halfpallet row
        }

        if (leftovers > 0) {
          console.warn('Could not fit leftovers', leftovers, 'for', artnr, 'in half pallets');
        }
      } else {
        newResults.push(result);
      }
    }
  }

  return newResults;
};

/* 7. Summary watchers */
export default function () {
  watch(
    () => [data.value.roofs, project.loading.calc_initial, id.value], // calc_initial, because the first calculation doesn't seem to work?
    async () => {
      // Don't calculate if no project is loaded (Helps prevent errors in initial load)
      if (!id.value) return;

      project.loading.calc = true; // Start loading

      debouncedCalc();
    },
    { deep: true, immediate: true },
  );
}

// Debounce to make sure all the changes have been applied and to catch excessive watch updates
const debouncedCalc = _.debounce(async () => {
  const roofs: Roof[] = data.value.roofs;
  const totalTiles: { [key: string]: TotalTiles } = {
    total: { total: 0, totalSvinn: 0, totalRounded: 0 },
  };
  let results: ResultRow[] = [];
  for (let i = 0; i < roofs.length; i++) {
    let roofResults: ResultRow[] = [];
    const r = roofs[i];
    const roof = getRoofCalc(i);
    const tileCalc = getTileCalc(i);
    roof.extensions = []; // Clear extension roofs early to fix Hörnvinkel synchronization issues like row 159
    try {
      roof.market = project.getMarket;
      if (r.roof.type !== RoofTypeIndex.None) {
        // Set the main roof
        roof.mainPart = await RoofPart.createRoofPart(roof, GetRoofType(r.roof.type));
        roof.mainPart.setOptions({
          a: r.roof.dimensions.a,
          b: r.roof.dimensions.b,
          c: r.roof.dimensions.c,
          angle: r.roof.dimensions.angle || 0,
          angle2: r.roof.dimensions.angle2,
        });
        roof.amount = r.roof.amount || 1;

        roof.setLäktavstånd(
          r.roof.dimensions.lathDistance == 'fixed',
          r.roof.dimensions.lathDistance == 'fixed' ? r.roof.dimensions.lathMeasurement : undefined,
        );
        roof.underlagstak = r.roof.dimensions.baseRoof;
        roof.selection.setOptions(getRoofTile(r.tile.family), r.tile.coating, r.tile.color, r.accessories.color, r.tile.nock);
        if (r.tile.gable == Gable.None) roof.selection.selGavelTillval = GavelTillval.None;
        if ([Gable.GablePan, Gable.GablePanNA, Gable.GablePanWide].includes(r.tile.gable)) roof.selection.selGavelTillval = GavelTillval.Gavelpanna;
        if (r.tile.gable == Gable.GableFittings) roof.selection.selGavelTillval = GavelTillval.Gavelbeslag;
        if (roof.selection.hasNockAnslutning()) roof.selection.selNockAnslutning = r.tile.nockConnector == NockConnector.Yes;
        roof.selection.selOverlapCarisma = r.tile.nockExtra == NockPannaExtra.Overlapp;
        roof.selection.selDoldAvrinningsplat = r.accessories.concealedDraining == ConcealedDraining.Yes;
        roof.selection.selTatningskloss = r.accessories.sealingAiring == SealingAiring.Yes;
        roof.selection.selBTS = r.extras.btsBaseRoof == BTSBaseRoof.Yes;

        // Set the roof's lath measurement
        if (r.tile.family !== TileFamily.None) {
          const lathMeasurement = roof.mainPart.calculateLäktavstånd();
          setLathMeasurement(r.id, lathMeasurement.läktavstånd1, lathMeasurement.läktavstånd2);
        }

        // Add roof extensions
        for (const ext of r.extensions) {
          const extPart = await RoofPart.createRoofPart(roof, GetRoofType(ext.roof.type), GetRoofForm(ext.roof.type));
          extPart.setOptions({
            a: ext.roof.dimensions.a,
            b: ext.roof.dimensions.b,
            c: ext.roof.dimensions.c,
            angle: ext.roof.dimensions.angle || 0,
            angle2: ext.roof.dimensions.angle2,
          });
          extPart.amount = ext.roof.amount || 1;
          roof.extensions.push(extPart);

          // Set the extension's lath measurement
          if (r.tile.family !== TileFamily.None) {
            const lathMeasurement = extPart.calculateLäktavstånd();
            setLathMeasurement(ext.id, lathMeasurement.läktavstånd1, lathMeasurement.läktavstånd2);
          }
        }

        // Write accessories
        roof.selection.selectedDrainAeratorAmount = r.accessories.drainAerator.amount;
        roof.selection.selectedDrainAeratorArtNos =
          tileCalc
            ?.drainAeratorItems?.(r.accessories.drainAerator.type, r.tile.coating, r.tile.color, r.accessories.color, r.roof.dimensions.baseRoof)
            ?.map?.((item) => item.artnr || '') || [];
        roof.selection.selectedVentilationHoodAmount = r.accessories.ventilationHood.amount;
        roof.selection.selectedVentilationHoodArtNos =
          tileCalc
            ?.ventilationHoodItems?.(r.accessories.ventilationHood.type, r.roof.dimensions.baseRoof, r.accessories.color, r.tile.family, r.tile.color)
            ?.map?.((item) => item.artnr || '') || [];

        const { angle: minMaxAngle } = getRoofMeasurements(i); //TODO: Check if this is correct
        const angle = project.isMansard(project.roofIndex) ? r.roof.dimensions.angle2 ?? 0 : r.roof.dimensions.angle;
        roof.selection.selectedSealantArtNo =
          (tileCalc?.sealantOptions?.(r.roof.type, r.tile.family, r.tile.nockConnector, minMaxAngle, r.accessories.color, market.value) ?? []).find(
            (sealant) => sealant.value === r.accessories.sealant,
          )?.artnr || '';
        roof.selection.selectedVentilationArtNo =
          (tileCalc?.ventilationOptions?.(r.tile.family, r.accessories.color, market.value) ?? []).find(
            (ventilation) => ventilation.value === r.accessories.ventilation,
          )?.artnr || '';
        roof.selection.selectedFixingTileArtNo =
          (tileCalc?.fixingsTileOptions?.(r.tile.family, r.roof.dimensions.baseRoof, market.value) || []).find(
            (fixingTile) => fixingTile.value === r.accessories.fixingTile,
          )?.artnr || '';
        // Add extra fixing tiles
        roof.selection.extraFixingTiles = roof.amount * r.accessories.extraFixingTiles;

        roof.selection.selectedFixingNockArtNos =
          tileCalc?.fixingsNockItems?.(r.accessories.fixingNock, r.accessories.color)?.map?.((item) => item.artnr || '') || [];

        const extensionTypes = r.extensions.map((ext) => ext.roof.type);
        roof.selection.selNockBoard =
          r.accessories.nockBoard == NockBoard.Yes &&
          (tileCalc?.nockOptions?.(r.roof.type, extensionTypes, r.tile.coating, r.tile.color, angle, market.value) ?? []).length > 0;

        const safe = (r.security.securitySystem.type === SecuritySystem.PW) ? new PWCalc(roof) : new SAFECalc(roof);
        safe.selColor = r.accessories.color;
        safe.selBattenSteps = r.security.battenStep.amount;
        safe.selMountingRail = r.security.battenStep.type === BattenStep.Connected && r.security.battenStep.amount > 0 ? r.security.mountingRail : 0;
        safe.calcTrackTilesForBattenSteps = r.security.trackTilesForBattenSteps;
        safe.selSlipProtection =
          market.value == MarketStr.Norge && r.security.battenStep.type === BattenStep.Connected && r.security.battenStep.amount === 0
            ? 0
            : r.security.slipProtection;
        safe.selRoofHatch = r.security.roofHatch;
        safe.selSafetyHook = r.security.safetyHook;
        safe.selSnowHook = r.security.snowHook;
        safe.selSnowSlideProtection = market.value == MarketStr.Norge && r.security.snowSlideObstacle == SnowSlideObstacle.Yes;
        safe.selIceStopper = market.value == MarketStr.Norge && r.security.snowSlideObstacle === SnowSlideObstacle.Yes ? r.security.iceStopper : 0;

        safe.battenStepArtNo =
          tileCalc
            ?.battenStepOptions?.(getRoofMeasurements(i).angle, r.roof.dimensions.facadeHeight, r.roof.dimensions.baseRoof, r.accessories.color, market.value)
            .find((option) => option.value == r.security.battenStep.type)?.artnr || '';
        safe.mountingRailArtNo = '083990';
        safe.slipProtectionArtNo = tileCalc?.slipProtectionItem?.(r.accessories.color)?.artnr || '';
        safe.roofHatchArtNo = tileCalc?.roofHatchItem(r.accessories.color)?.artnr || '';
        safe.safetyHookArtNo = tileCalc?.safetyHookItem(r.accessories.color)?.artnr || '';
        safe.snowHookArtNo = tileCalc?.snowHookItem(r.accessories.color)?.artnr || '';
        safe.snowSlideObstacleArtNo = tileCalc?.snowSlideObstacleItem(r.accessories.color)?.artnr || '';
        safe.snowSlideObstacleKonsolArtNo = tileCalc?.snowSlideObstacleKonsolItem(r.accessories.color)?.artnr || '';
        safe.iceStopperArtNo = tileCalc?.iceStopperItem(r.accessories.color)?.artnr || '';

        // SAFE specific values
        safe.useSAFE = [SecuritySystem.SAFE, SecuritySystem.PW].includes(r.security.securitySystem.type);
        safe.snowSafetyLengths = r.security.securitySystem.snowSafety;
        safe.roofBridgeLengths = r.security.securitySystem.roofBridge;
        safe.hatchSafetyLengths = r.security.securitySystem.hatchSafety;
        safe.roofRailsAmount = r.security.securitySystem.railsAmount;
        safe.snowZone = r.security.securitySystem.snowZone;
        safe.fasteningEyeAmount = r.security.securitySystem.fasteningEye;

        if (r.tile.family !== TileFamily.None && roof.selection.tileColor !== TileColor.None) {
          project.setSAFEDistanceConsole(r.id, safe.calculateDistanceConsole());
        }

        // Note: This is probably not optimal, because storing the entire result separately seems unnecessary
        if (r.tile.family !== TileFamily.None && r.tile.color !== TileColor.None) {
          roofResults = addResults(roofResults, await roof.calculateAll(), r.id);

          // Add roof extras
          roofResults = addResults(roofResults, await roof.calculateExtras(r.extras), r.id);

          roofResults = addResults(roofResults, await safe.calculate(), r.id);

          roofResults = await adjustResults(roofResults, roof);

          results = addResults(results, roofResults);

          // Add total tiles
          const calc = await roof.sumArticles();
          totalTiles[r.id] = roof.calculateMainTile(calc);
          totalTiles.total.total += totalTiles[r.id].total;
          totalTiles.total.totalSvinn += totalTiles[r.id].totalSvinn;
          totalTiles.total.totalRounded += totalTiles[r.id].totalRounded;
          if (totalTiles.total?.tilePacking != 'p')
            // BENT-175: Leave as 'p' if multiple conflicting packings
            totalTiles.total.tilePacking = totalTiles[r.id].tilePacking;
        }
      } else {
        console.warn('No roof type selected');
      }
    } catch (err) {
      console.error(`Error while calculating roof(${r.id})[${i}]:`, err);
    }
  }

  // Moves all child articles (specified by lambda function) to the index after their parent article (second lambda)
  const moveAfterParent = (findChildren: (row: ResultRow) => boolean, findParent: (row: ResultRow, child: ResultRow) => boolean) => {
    const children = results.filter(findChildren);
    for (const child of children) {
      const index = results.indexOf(child);
      const parentIndex = results.findIndex((art) => findParent(art, child));
      if (parentIndex == -1) continue; // No parent article

      // Move article to index after its parent
      results.splice(index, 1);
      results.splice(parentIndex + 1, 0, child);
    }
  };

  // Move any halftiles to right after their main tile article
  moveAfterParent(
    (art) => art.art == RoofArticle.HalvPanna || art.art == RoofArticle.PultHalvPanna,
    (art, child) => art.art == RoofArticle.TakPanna && _.intersection(Object.keys(art.origins), Object.keys(child.origins)).length > 0,
  );

  // Move any halfpallet articles to right after their main article
  moveAfterParent(
    (art) => art.artnr.endsWith('.5'),
    (art, child) => art.artnr === child.artnr.substring(0, child.artnr.length - 2),
  );

  // Adjust results to account for halfpallets
  setCalculatedAmounts(results);
  setCalculatedTileCount(totalTiles);
  project.loading.calc = false; // Stop loading
  project.loading.calc_initial = false; // Stop loading
}, 250);
