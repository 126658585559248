import { defineStore } from 'pinia'
import pinia from '@/store/store'
import { nanoid } from 'nanoid'
import dayjs from 'dayjs'

interface Notification {
    id: string
    type: 'success' | 'error' | 'info' | 'warn' | string
    message: string
    hovered: boolean
    expires: Date
}

export const useNotificationStore = defineStore({
    id: 'notification',
    state: () => ({
        notifications: [] as Notification[],
    }),
    getters: {
    },
    actions: {
        addNotification({ message = '', type = 'error' }, duration = 3000) {
            const id = nanoid();
            const expires = dayjs().add(duration, 'ms').toDate();
            this.notifications.push({ id, message, type, hovered: false, expires });
            return id;
        },
        removeNotification(id: string) {
            this.notifications = this.notifications.filter(n => n.id !== id);
        },
        updateExpiration(id: string, duration = 1000) {
            const expires = dayjs().add(duration, 'ms').toDate();
            this.notifications = this.notifications.map(n => n.id === id ? { ...n, expires } : n);
        },
        setHovered(id: string | null) {
            this.notifications = this.notifications.map(n => n.id === id ? { ...n, hovered: true } : { ...n, hovered: false });
        },
    }
})

// Expiation handler
const store = useNotificationStore(pinia);
setInterval(() => {
    store.notifications = store.notifications.filter(n => n.expires > new Date() || n.hovered);
}, 1000);
