<script setup lang="ts">
import { RouterView } from 'vue-router';
import { useFrameStore } from '@/store/frame';
import { storeToRefs } from 'pinia';
import init from '@/init';
import watchers from '@/watchers';
import { id } from '@/helpers';
import NotificationsHeader from '@/components/NotificationsHeader.vue';
import BendersLoader from '@/components/BendersLoader.vue';

init();
watchers();

const { isRedirecting } = storeToRefs(useFrameStore());
</script>

<template>
  <section class="hero-body">
    <div class="container has-text-centered">
      <div class="columns is-centered">
        <div v-if="isRedirecting" class="is-flex is-justify-content-center">
          <BendersLoader :hidden="false" size="large mt-6" />
        </div>
        <template v-else>
          <NotificationsHeader />
          <RouterView class="column" :key="id" />
        </template>
      </div>
    </div>
  </section>
</template>

<style lang="sass">
#app
  font-family: futura-pt, sans-serif
  -webkit-font-smoothing: antialiased

  max-width: 1280px
  margin: 0 auto
  padding: 1rem
  font-weight: normal
  .hero-body
    padding: 1rem 3rem
</style>
