<script setup lang="ts">
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useNotificationStore } from "@/store/notification";
import pinia from "@/store/store";

const notification = useNotificationStore(pinia);
const refs = storeToRefs(notification);
const notifications = computed(() => refs.notifications.value || []);
</script>

<template>
  <div class="notifications">
    <div
      class="notification"
      v-for="n in notifications"
      :key="n.id"
      :class="n.type"
      @click="notification.removeNotification(n.id)"
      @mouseenter="notification.setHovered(n.id)"
      @mouseleave="notification.setHovered(null)"
    >
      {{ $t(n.message) }}
      <button class="delete"></button>
    </div>
  </div>
</template>

<style lang="sass">
@import "@/assets/variables.sass"

.notifications
    position: fixed
    top: 0
    left: 0
    right: 0
    z-index: 20
    .notification
        background: $benders-red
        color: $white
        &:hover
            cursor: pointer
            .delete
                display: block
                opacity: 1
        .delete
            display: none
            opacity: 0
</style>