import type { RoofTile } from '@/calculation/rooftile';
import type { RoofTileOption } from '@/types';
import type { RoofDimensions } from '@/project';

import { watch, nextTick, computed } from 'vue';
import { storeToRefs } from 'pinia';
// import _ from 'lodash';

// Calculations
import { NockPannaVariant, NockPannaExtra, RoofTypeIndex, TileColor, TileFinish, TileFamily, NockConnector, MarketStr } from '@/calculation/common';
import { getSelectableFamilies } from '@/calculation/rooftile';

// stores
import pinia from '@/store/store';
import { useProjectStore } from '@/store/project';
import { useArticleStore } from '@/store/article';

import { Gable, Sealant, Ventilation, VentilationHood, FixingsTile, FixingsNock, AccessoryColor, DrainAerator, SecuritySystem, BattenStep, FacadeHeight, ExposedWeather } from '@/enums';
import { activeExtension, getLanguage } from '@/helpers';
import { defaults } from '@/defaults';

const project = useProjectStore(pinia);

const { calc_tile, selections, activeRoof, roofIndex, market } = storeToRefs(project);
const { getRoofCalcById, getRoofMeasurements, getRoofIndexById } = useProjectStore(pinia);
const { getArticlesByPrefix } = useArticleStore(pinia);

// Global watchers
export default function () {
  // Set selections for extensions
  watch(
    () => activeExtension.value?.type,
    () => {
      if (!activeExtension.value?.editing?.roof) return;
      activeExtension.value.roof.type = RoofTypeIndex.None; // Reset roof type if extension type is changed
    },
  );

  // Set selections for tile
  watch(
    () => [activeRoof.value.roof, activeExtension.value?.roof],
    () => {
      // tile sort
      const order = {
        [TileFamily.Palema]: 1,
        [TileFamily.Exklusiv]: 2,
        [TileFamily.Carisma]: 3,
        [TileFamily.HansaFalsatLertegel]: 4,
        [TileFamily.HansaFalsatLertegelRakFramkant]: 5,
        [TileFamily.TvillingFalsatLertegel]: 6,
        [TileFamily.TvillingLertegelRakFramkant]: 7,
        [TileFamily.PianoFalsatLertegel]: 8,
        [TileFamily.Strängpressat2kupLertegel]: 9,
      } as Record<TileFamily, number>;

      const roofs = [activeRoof.value, ...activeRoof.value.extensions];
      const families = {} as Record<TileFamily, RoofTileOption>;

      for (const roof of roofs) {
        const { angle } = getRoofMeasurements(getRoofIndexById(roof.id)); //TODO: Check if this is correct
        const lathMeasurement = roof.roof.dimensions.lathDistance === 'fixed' ? roof.roof.dimensions.lathMeasurement : null;
        const baseroof = roof.roof.dimensions.baseRoof;
        getSelectableFamilies(lathMeasurement, angle, baseroof).forEach((option) => {
          const key = option.tile.family as TileFamily;
          option.order = order[key];
          if (families[key] === undefined) {
            families[key] = option;
          } else {
            families[key].valid = families[key].valid && option.valid;
            families[key].message = families[key].message || option.message;
          }
        });

        // If the selected roof tile is no longer selectable, clear tile selections
        if (roof.tile && !families[roof.tile.family]?.valid) {
          roof.tile.family = TileFamily.None;
          roof.tile.coating = TileFinish.None;
          roof.tile.color = TileColor.None;
          // roof.tile.nock = NockPannaVariant.Standard;
          // roof.tile.nockExtra = NockPannaExtra.None;
          roof.tile.nockConnector = NockConnector.Yes;
        }
      }

      // Set tile options
      selections.value.tile.family = Object.values(families).sort((a, b) => {
        return (a?.order ?? 0) - (b?.order ?? 0);
      });
    },
    { deep: true },
  );

  // BENT-115: Set slip protection to 0 if facade height is set to value that doesn't support slip protection
  watch(
    () => activeRoof.value.roof.dimensions.facadeHeight,
    (newHeight, oldHeight) => {
      if (![FacadeHeight.Less3m, FacadeHeight.Less4m, FacadeHeight.Less5m].includes(newHeight)) activeRoof.value.security.slipProtection = 0;
      // If we came from an invalid facade height, set slip protection amount to default
      else if (![FacadeHeight.Less3m, FacadeHeight.Less4m, FacadeHeight.Less5m].includes(oldHeight))
        activeRoof.value.security.slipProtection = defaults.roof.security.slipProtection;
    },
  );

  // Set selections for accessories
  watch(
    () => [
      activeRoof.value.roof,
      activeRoof.value.tile,
      activeRoof.value.accessories.color,
      // activeRoof.value.roof.dimensions.exposedWeather,
      project.loading.calc_initial,
      calc_tile.value.variantsLoaded,
      getLanguage.value,
    ],
    async () => {
      await calc_tile.value.variantsLoaded;
      const { angle: minMaxAngle } = getRoofMeasurements(); //TODO: Check if this is correct
      const angle = project.isMansard(project.roofIndex) ? activeRoof.value.roof.dimensions.angle2 ?? 0 : activeRoof.value.roof.dimensions.angle ?? 0;

      // Tile
      selections.value.tile.coating = calc_tile.value?.finishOptions?.() ?? [];
      selections.value.tile.color = calc_tile.value?.colorOptions?.(activeRoof.value.tile.coating) ?? [];
      const extensionTypes = activeRoof.value.extensions.map((ext) => ext.roof.type);
      selections.value.tile.nock =
        calc_tile.value?.nockOptions?.(activeRoof.value.roof.type, extensionTypes, activeRoof.value.tile.coating, activeRoof.value.tile.color, angle, market.value) ?? [];
      selections.value.tile.nockconnector = calc_tile.value?.nockConnectorOptions?.(activeRoof.value.tile.family, market.value) ?? [];
      selections.value.tile.nockextra = calc_tile.value?.nockExtraOptions?.(activeRoof.value.tile.nock) ?? [];
      selections.value.tile.gable =
        calc_tile.value?.gableOptions(activeRoof.value.roof.type, activeRoof.value.tile.family, activeRoof.value.tile.coating, activeRoof.value.tile.color) ??
        [];

      // Accessories
      selections.value.accessory.color = calc_tile.value?.accessoryColorOptions?.(activeRoof.value.tile.color) ?? [];
      selections.value.accessory.drainAerator = calc_tile.value?.drainAeratorOptions?.(activeRoof.value.tile.family, activeRoof.value.roof.dimensions.angle) ?? [];
      selections.value.accessory.sealant =
        calc_tile.value?.sealantOptions?.(
          activeRoof.value.roof.type,
          activeRoof.value.tile.family,
          activeRoof.value.tile.nockConnector,
          minMaxAngle,
          activeRoof.value.accessories.color,
          market.value,
        ) ?? [];
      selections.value.accessory.ventilation =
        calc_tile.value?.ventilationOptions?.(activeRoof.value.tile.family, activeRoof.value.accessories.color, market.value) ?? [];
      selections.value.accessory.fixingTile =
        calc_tile.value?.fixingsTileOptions?.(activeRoof.value.tile.family, activeRoof.value.roof.dimensions.baseRoof, market.value) || [];
      selections.value.accessory.fixingNock =
        calc_tile.value?.fixingsNockOptions?.(ExposedWeather.No, activeRoof.value.tile.family, activeRoof.value.tile.nock) || [];
      selections.value.security.battenStep = calc_tile.value?.battenStepOptions?.(
        minMaxAngle,
        activeRoof.value.roof.dimensions.facadeHeight,
        activeRoof.value.roof.dimensions.baseRoof,
        activeRoof.value.accessories.color,
        project.market,
      );
      selections.value.security.trackingTiles = calc_tile.value?.trackingTileOptions(activeRoof.value.tile.family, activeRoof.value.tile.coating, activeRoof.value.tile.color);
    },
    { deep: true },
  );

  // Preselected handling
  const lastRoofID = {} as Record<string, string>;
  const shouldUpdate = (key: string) => {
    const doUpdate = !project.loading.fetch && !project.loading.calc_initial && project.id && (!lastRoofID[key] || lastRoofID[key] === activeRoof.value.id);
    lastRoofID[key] = activeRoof.value.id;
    return doUpdate;
  };
  // Nock
  watch(
    () => [activeRoof.value.tile.color, activeRoof.value.roof.type, getRoofMeasurements().angle],
    () => {
      nextTick(() => {
        if (!shouldUpdate('nock')) return;
        activeRoof.value.tile.nock = (selections.value.tile.nock.find((v) => v.default)?.value as NockPannaVariant) || NockPannaVariant.None;
      });
    },
  );
  // Nock extra
  watch(
    () => [activeRoof.value.tile.family, activeRoof.value.tile.coating, activeRoof.value.tile.nock],
    () => {
      nextTick(() => {
        if (!shouldUpdate('nockextra')) return;
        const options = calc_tile.value?.nockExtraOptions?.(activeRoof.value.tile.nock) ?? [];
        if (options.length > 0) {
          activeRoof.value.tile.nockExtra = NockPannaExtra.Nockbygel;
        } else {
          activeRoof.value.tile.nockExtra = NockPannaExtra.None;
        }
      });
    },
  );

  // Nock connector
  watch(
    () => [activeRoof.value.tile.family],
    () => {
      nextTick(() => {
        if (!shouldUpdate('nockconnector')) return;
        activeRoof.value.tile.nockConnector = (selections.value.tile.nockconnector.find((v) => v.default)?.value as NockConnector) || NockConnector.No;
      });
    },
  );
  // Gable
  watch(
    () => [activeRoof.value.tile.family, activeRoof.value.roof.type],
    () => {
      nextTick(() => {
        if (!shouldUpdate('gable')) return;
        activeRoof.value.tile.gable = Gable.None;
      });
    },
  );
  watch(
    () => selections.value.tile.gable,
    (value) => {
      nextTick(() => {
        if (!shouldUpdate('gable')) return;
        // Check if the current gable is still valid
        if (!value.find((v) => v.value === activeRoof.value.tile.gable)) {
          activeRoof.value.tile.gable = Gable.None;
        }
      });
    },
  );
  // Accessory color
  watch(
    () => [activeRoof.value.tile.color],
    () => {
      nextTick(() => {
        if (!shouldUpdate('accessorycolor')) return;
        activeRoof.value.accessories.color = (selections.value.accessory.color.find((v) => v.default)?.value as AccessoryColor) || AccessoryColor.None;
      });
    },
  );
  // Drain aerator
  watch(
    () => [activeRoof.value.tile.family, activeRoof.value.roof.dimensions.angle],
    () => {
      nextTick(() => {
        if (!shouldUpdate('drainaerator')) return;
        activeRoof.value.accessories.drainAerator.type =
          (selections.value.accessory.drainAerator.find((v) => v.default)?.value as DrainAerator) || DrainAerator.None;
      });
    },
  );
  // Ventilaion hood
  watch(
    () => [activeRoof.value.roof.dimensions.angle],
    () => {
      nextTick(() => {
        if (!shouldUpdate('ventilationHood')) return;
        if (activeRoof.value.roof.dimensions.angle > 45)
          activeRoof.value.accessories.ventilationHood.type = VentilationHood.None;
      });
    },
  );
  // Sealant
  watch(
    () => [activeRoof.value.tile.family, activeRoof.value.tile.nockConnector, getRoofMeasurements().angle],
    () => {
      nextTick(() => {
        if (!shouldUpdate('sealant')) return;
        activeRoof.value.accessories.sealant = (selections.value.accessory.sealant.find((v) => v.default)?.value as Sealant) || Sealant.None;
      });
    },
  );
  // Ventilation
  watch(
    () => [activeRoof.value.tile.family, activeRoof.value.accessories.color],
    () => {
      nextTick(() => {
        if (!shouldUpdate('ventilation')) return;
        activeRoof.value.accessories.ventilation = (selections.value.accessory.ventilation.find((v) => v.default)?.value as Ventilation) || Ventilation.None;
      });
    },
  );
  // Fixing tile
  watch(
    () => [activeRoof.value.tile.family, activeRoof.value.roof.dimensions.baseRoof],
    () => {
      nextTick(() => {
        if (!shouldUpdate('fixingtile')) return;
        activeRoof.value.accessories.fixingTile = (selections.value.accessory.fixingTile.find((v) => v.default)?.value as FixingsTile) || FixingsTile.None;
      });
    },
  );
  // Fixing nock
  watch(
    () => [/* activeRoof.value.roof.dimensions.exposedWeather, */ activeRoof.value.tile.family, activeRoof.value.tile.nock],
    () => {
      nextTick(() => {
        if (!shouldUpdate('fixingnock')) return;
        activeRoof.value.accessories.fixingNock = (selections.value.accessory.fixingNock.find((v) => v.default)?.value as FixingsNock) || FixingsNock.None;
      });
    },
  );
  // Roof Security (Batten step) - Clear selection if angle is too steep
  const angle = computed(() => getRoofMeasurements(roofIndex.value).angle);
  watch(
    () => [activeRoof.value.tile.family, angle.value, activeRoof.value.roof.dimensions.facadeHeight, activeRoof.value.roof.dimensions.baseRoof],
    () => {
      nextTick(() => {
        if (!shouldUpdate('battenstepangle')) return;
        if (angle.value.max >= 55) {
          activeRoof.value.security.battenStep.type = BattenStep.None;
        } else {
          activeRoof.value.security.battenStep.type = (selections.value.security.battenStep.find((v) => v.default)?.value as BattenStep) || BattenStep.None;
        }
      });
    },
  );
  // Roof Security (Batten step) - Set default amount based on tile rows
  watch(
    () => [activeRoof.value.roof.dimensions, activeRoof.value.tile.family] as [RoofDimensions, TileFamily],
    ([, family]: [RoofDimensions, TileFamily]) => {
      nextTick(async () => {
        const valueLocked = activeRoof.value.valueLocked['battenstepamount'] == true;
        if (valueLocked || !shouldUpdate('battenstepamount')) return;
        await project.waitForCalculation();
        if (!shouldUpdate('battenstepamount')) return;
        if (family !== TileFamily.None) {
          activeRoof.value.security.battenStep.amount = getRoofCalcById(activeRoof.value.id)?.getAntalTaksteg() || 0;
        }
      });
    },
    { deep: true },
  );

  /* 2. Roof Tiles */
  watch(
    () => calc_tile.value,
    (c: RoofTile) => {
      // Fetch articles
      nextTick(async () => {
        await getArticlesByPrefix(c.artNrBase);
      });
    },
  );

  // Clear coating and color when family changes
  watch(
    () => [activeRoof.value.tile.family, roofIndex.value],
    (value: number[], old: number[]) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [family, index] = value;
      const [oldFamily, oldIndex] = old;
      if (oldFamily && index === oldIndex) {
        activeRoof.value.tile.coating = TileFinish.None;
        activeRoof.value.tile.color = TileColor.None;
        // activeRoof.value.tile.nock = NockPannaVariant.Standard;
        activeRoof.value.tile.nockExtra = NockPannaExtra.None;
        activeRoof.value.tile.nockConnector = NockConnector.Yes;
      }
    },
  );

  // Clear color if coating changes
  watch(
    () => [activeRoof.value.tile.coating, roofIndex.value],
    (value: number[], old: number[]) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [coating, index] = value;
      const [oldCoating, oldIndex] = old;
      if (oldCoating && index === oldIndex) {
        activeRoof.value.tile.color = TileColor.None;
        // activeRoof.value.tile.nock = NockPannaVariant.Standard;
        activeRoof.value.tile.nockExtra = NockPannaExtra.None;
      }
    },
  );

  // Clear security system if tile family changes (Only for 'Höganäs Strängpressat 2kup Lertegel') or norwegian market is selected
  watch(
    () => [activeRoof.value.tile.family, roofIndex.value, project.market] as [TileFamily, number, MarketStr],
    (value: [TileFamily, number, MarketStr], old: [TileFamily, number, MarketStr]) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [family, index, market] = value;
      const [oldFamily, oldIndex, oldMarket] = old;
      if (
        (oldFamily && index === oldIndex && family === TileFamily.Strängpressat2kupLertegel) ||
        (market === MarketStr.Norge && oldMarket !== MarketStr.Norge)
      ) {
        activeRoof.value.security.securitySystem.type = SecuritySystem.None;
      }
    },
  );

  // BENT-147: Clear facadeHeight if market changes (Norway doesn't have this option)
  watch(
    () => project.market,
    (value: MarketStr) => {
      if (value === MarketStr.Norge) activeRoof.value.roof.dimensions.facadeHeight = FacadeHeight.None;
    },
    { immediate: true },
  );

  // BENT-204: Prevent mounting rails from exceeding batten steps
  watch(
    () => [activeRoof.value.security.battenStep.amount, activeRoof.value.security.mountingRail],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([battenVal, mountVal], [oldBattenVal, oldMountVal]) => {
      if (activeRoof.value.security.mountingRail > activeRoof.value.security.battenStep.amount)
        activeRoof.value.security.mountingRail = activeRoof.value.security.battenStep.amount;

      if (battenVal > 0 && mountVal < 1)
        activeRoof.value.security.mountingRail = 1;
    }
  );
}
