/* eslint-disable no-case-declarations */
import type { SectionValues, SectionValueItem, Article, ArticleMap } from '@/types';
import type { ExtraArticle, Tile } from '@/project';

import { watch } from 'vue';
import { storeToRefs } from 'pinia';
import _ from 'lodash';

// Calculations
import { Underlagstak, TileFamily, TileFinish, TileFinishMap, TileFamilyMap, Material, MarketStr, RoofArticle, NockPannaVariant } from '@/calculation/common';

// stores
import pinia from '@/store/store';
import { useProjectStore } from '@/store/project';
import { useArticleStore } from '@/store/article';

import { AccessoryColor, ExtraAssortment, FacadeHeight, accessoryColorMap } from '@/enums';
import { getArtHash, getArtPrefix } from '@/helpers/utilities';
import { getRoofTile } from '@/helpers/tile';
import { useLoadingStore } from '@/store/loading';

const project = useProjectStore(pinia);
const { setLoading } = useLoadingStore(pinia);

const { calc_tile, selections, activeRoof } = storeToRefs(project);
const { getArticles, getArticlesByPrefix, getAccessories } = useArticleStore(pinia);

const getAssortments = async ({
  tile,
  angle,
  accessoryColor,
  baseroof,
  facadeHeight,
}: {
  tile: Tile;
  angle: number;
  accessoryColor: AccessoryColor;
  baseroof: Underlagstak;
  facadeHeight: FacadeHeight;
}): Promise<{ [key: string]: (string | { artnr: string; sort: string })[] }> => {
  const acc = {
    [ExtraAssortment.TakpannorOchTillbehor]: [],
    [ExtraAssortment.VentilationGenomforing]: [],
    [ExtraAssortment.TatningLuftning]: [],
    [ExtraAssortment.Taksakerhet]: [],
    [ExtraAssortment.Infastning]: [],
    [ExtraAssortment.OvrigaTillbehor]: [],
  } as { [key: string]: (string | { artnr: string; sort: string })[] };
  const roofTile = getRoofTile(tile.family);

  for (const key of Object.keys(acc) as ExtraAssortment[]) {
    let excludeTileFamily = '';
    if (tile.family === TileFamily.HansaFalsatLertegel) {
      excludeTileFamily = TileFamilyMap[TileFamily.HansaFalsatLertegelRakFramkant];
    } else if (tile.family === TileFamily.TvillingFalsatLertegel) {
      excludeTileFamily = TileFamilyMap[TileFamily.TvillingLertegelRakFramkant];
    }

    const items = [] as (string | { artnr: string; sort: string })[];
    let articles = {} as ArticleMap;
    switch (key) {
      case ExtraAssortment.VentilationGenomforing:
        items.push(...['060400', '060300', '060800']);
        switch (baseroof) {
          case Underlagstak.RåspontMedPapp:
            break;
          default:
            items.push('060000');
            break;
        }
        break;
      case ExtraAssortment.TatningLuftning:
        items.push(...['098002', '097200', '091990']);
        switch (accessoryColor) {
          case AccessoryColor.GalvanizedGreySilver:
            items.push(...['118490', '118520', '092620', '092220', '092320', '091820']);
            break;
          case AccessoryColor.Black:
            items.push(...['118420', '118520', '092620', '092220', '092320', '091820']);
            break;
          case AccessoryColor.BrickRed:
            items.push(...['118424', '118524', '092624', '092224', '092324', '091824']);
            break;
        }
        if (tile.family == TileFamily.Carisma) items.push('C92220', 'C97190');
        break;
      case ExtraAssortment.Infastning:
        items.push(...['096400', '096390', '113891']);
        break;
      case ExtraAssortment.OvrigaTillbehor:
        items.push(...['030520', '030524', '030720', '030724', '030320', '030900']);

        // eslint-disable-next-line no-case-declarations
        articles = await getArticlesByPrefix('0989,H983', true, {
          colorgroup: [TileFinishMap[tile.coating]],
          colornumber: [tile.color],
        });
        items.push(...Object.values(articles).map((a) => a.ArtNr));
        break;
      case ExtraAssortment.TakpannorOchTillbehor:
        articles = await getAccessories(
          {
            assortment: calc_tile.value.material === Material.Betong ? 'Betongtillbehör' : 'Lertegeltillbehör',
            colorgroup: [TileFinishMap[tile.coating]],
            colornumber: [tile.color],
            tilefamily: TileFamilyMap[tile.family],
            excludeTileFamily: [excludeTileFamily],
          },
          true,
        );
        items.push(...Object.values(articles).map((a) => a.ArtNr));
        articles = await getAccessories(
          {
            assortment: calc_tile.value.material === Material.Betong ? 'Betongtakpannor' : 'Lertegeltakpannor',
            colorgroup: [TileFinishMap[tile.coating]],
            colornumber: [tile.color],
            tilefamily: TileFamilyMap[tile.family],
            excludeTileFamily: [excludeTileFamily],
          },
          true,
        );
        items.push(...Object.values(articles).map((a) => a.ArtNr));

        const nockTile = roofTile.itemNos[tile.nock == NockPannaVariant.Standard ? RoofArticle.NockPanna : RoofArticle.NockPannaAlt];
        if (tile.family != TileFamily.None && roofTile?.variants?.[tile.coating]?.[tile.color] && getArtPrefix(nockTile) == '0300') {
          items.push({ artnr: '0310' + roofTile.variants[tile.coating][tile.color].nr, sort: 'aaaa' });
        }
        break;
      case ExtraAssortment.Taksakerhet:
        // Fetch all safety articles w/ correct color-postfix
        articles = await getAccessories({
          assortment: 'Taksäkerhet',
          colornumber: accessoryColorMap[accessoryColor],
        });
        const availableArticles = Object.values(articles).map((a) => a?.ArtNr);

        // Determine which articles should be shown
        const whitelisted: string[] = [];

        whitelisted.push(
          ...[
            '0701xx',
            '0813xx',
            '0814xx',
            '083990',
            '1300xx',
            '130190',
            '1308xx',
            '132900',
            '1343xx',
            '1351xx',
            '136090',
            '1369xx',
            '1377xx',
            '1378xx',
            '1396xx',
            '1397xx',
            '1398xx',
          ],
        );

        const koppladeTaksteg = roofTile.getArticle(RoofArticle.KoppladeTaksteg);
        if (koppladeTaksteg) whitelisted.push(getArtPrefix(koppladeTaksteg) + 'xx');

        if ([MarketStr.Sverige, MarketStr.Tyskland].includes(project.market) && [FacadeHeight.Less3m, FacadeHeight.Less4m].includes(facadeHeight)) {
          whitelisted.push('0817xx');

          if (angle >= 14 && angle <= 45) whitelisted.push('0712xx');
        }

        if ([FacadeHeight.Less3m, FacadeHeight.Less4m, FacadeHeight.Less5m].includes(facadeHeight)) whitelisted.push(...['0729xx']);

        switch (tile.family) {
          case TileFamily.Palema:
            whitelisted.push('1372xx');
            whitelisted.push('1348xx');
            if ([FacadeHeight.Less3m, FacadeHeight.Less4m].includes(facadeHeight) && angle >= 14 && angle <= 45) whitelisted.push(...['0762xx', '0724xx']);
            break;

          case TileFamily.Exklusiv:
            whitelisted.push('1371xx');
            whitelisted.push('1348xx');
            if ([FacadeHeight.Less3m, FacadeHeight.Less4m].includes(facadeHeight) && angle >= 14 && angle <= 45) whitelisted.push('0723xx');
            break;

          case TileFamily.Carisma:
            whitelisted.push('C832xx');
          // Fallthrough
          case TileFamily.PianoFalsatLertegel:
            whitelisted.push(...['171090', '171090-10', 'C1348xx']);
            break;

          case TileFamily.HansaFalsatLertegel:
          case TileFamily.HansaFalsatLertegelRakFramkant:
            if ([FacadeHeight.Less3m, FacadeHeight.Less4m].includes(facadeHeight) && angle >= 14 && angle <= 45) whitelisted.push('H763xx');
            whitelisted.push('L1374xx');
            whitelisted.push('1348xx');
            if ([FacadeHeight.Less5m, FacadeHeight.To58, FacadeHeight.More8m].includes(facadeHeight) && angle >= 14 && angle <= 55)
              whitelisted.push(...['H841xx', 'H831xx']);
            break;

          case TileFamily.TvillingFalsatLertegel:
          case TileFamily.TvillingLertegelRakFramkant:
            if ([FacadeHeight.Less3m, FacadeHeight.Less4m].includes(facadeHeight) && angle >= 14 && angle <= 45) whitelisted.push('H764xx');
            if ([FacadeHeight.Less3m, FacadeHeight.Less4m].includes(facadeHeight)) whitelisted.push('0828xx');
            whitelisted.push('1348xx');
            whitelisted.push('L1375xx');
            if ([FacadeHeight.Less5m, FacadeHeight.To58, FacadeHeight.More8m].includes(facadeHeight) && angle >= 14 && angle <= 55) whitelisted.push('H845xx');
            if ([FacadeHeight.Less5m, FacadeHeight.To58, FacadeHeight.More8m].includes(facadeHeight) && angle >= 14 && angle <= 65) whitelisted.push('L825xx');
            break;

          case TileFamily.Strängpressat2kupLertegel:
            whitelisted.push(...['L1348xx', 'L1372xx']);
            break;
        }

        if (
          [TileFamily.Palema, TileFamily.Carisma].includes(tile.family) &&
          [FacadeHeight.Less3m, FacadeHeight.Less4m].includes(facadeHeight) &&
          angle >= 14 &&
          angle <= 45
        )
          whitelisted.push('0722xx');

        if (
          [TileFamily.Palema, TileFamily.Carisma, TileFamily.PianoFalsatLertegel].includes(tile.family) &&
          [FacadeHeight.Less5m, FacadeHeight.To58, FacadeHeight.More8m].includes(facadeHeight) &&
          angle >= 14 &&
          angle <= 65
        )
          whitelisted.push('0833xx');

        if (
          [TileFamily.Palema, TileFamily.PianoFalsatLertegel].includes(tile.family) &&
          [FacadeHeight.Less5m, FacadeHeight.To58, FacadeHeight.More8m].includes(facadeHeight) &&
          angle >= 14 &&
          angle <= 55
        )
          whitelisted.push('0843xx');

        if ([TileFamily.Exklusiv, TileFamily.Carisma, TileFamily.Strängpressat2kupLertegel].includes(tile.family)) {
          if ([FacadeHeight.Less3m, FacadeHeight.Less4m].includes(facadeHeight)) {
            if (angle >= 14 && angle <= 45) {
              whitelisted.push(...['0761xx', '0721xx']);
            }
            if (angle >= 14 && angle <= 65) whitelisted.push('0831xx');
          } else if ([FacadeHeight.Less5m, FacadeHeight.To58, FacadeHeight.More8m].includes(facadeHeight))
            if (angle >= 14 && angle <= 55) whitelisted.push('0811xx');
        }

        if (
          [
            TileFamily.Palema,
            TileFamily.Exklusiv,
            TileFamily.HansaFalsatLertegel,
            TileFamily.HansaFalsatLertegelRakFramkant,
            TileFamily.TvillingFalsatLertegel,
            TileFamily.TvillingLertegelRakFramkant,
            TileFamily.Strängpressat2kupLertegel,
          ].includes(tile.family)
        )
          whitelisted.push('0838xx');

        if (
          [
            TileFamily.Palema,
            TileFamily.Exklusiv,
            TileFamily.HansaFalsatLertegel,
            TileFamily.HansaFalsatLertegelRakFramkant,
            TileFamily.TvillingFalsatLertegel,
            TileFamily.TvillingLertegelRakFramkant,
          ].includes(tile.family)
        ) {
          whitelisted.push(...['170990', '170990-10', '1348xx', '1376xx']);
        }

        if (
          [
            TileFamily.Palema,
            TileFamily.Exklusiv,
            TileFamily.Carisma,
            TileFamily.HansaFalsatLertegel,
            TileFamily.HansaFalsatLertegelRakFramkant,
            TileFamily.PianoFalsatLertegel,
          ].includes(tile.family) &&
          [FacadeHeight.Less3m, FacadeHeight.Less4m].includes(facadeHeight)
        )
          whitelisted.push('0818xx');

        if (
          [
            TileFamily.Palema,
            TileFamily.Exklusiv,
            TileFamily.Carisma,
            TileFamily.HansaFalsatLertegel,
            TileFamily.HansaFalsatLertegelRakFramkant,
            TileFamily.TvillingFalsatLertegel,
            TileFamily.TvillingLertegelRakFramkant,
            TileFamily.PianoFalsatLertegel,
          ].includes(tile.family)
        ) {
          whitelisted.push(
            ...[
              '1701xx',
              '1701xx-5',
              '1702xx',
              '1703xx',
              '1703xx-10',
              '1704xx',
              '1705xx',
              '1705xx-10',
              '1706xx',
              '1707xx',
              '1708xx',
              '1713xx',
              '1714xx',
              '1715xx',
              '1722xx',
              '1724xx',
              '1726xx',
            ],
          );
        }

        // Filter availableArticles on our whitelist. Some whitelisted articles might not appear if their article data isn't available
        const artMap: { [key: string]: string } = {};
        for (const art of availableArticles) {
          if (!art) continue;
          artMap[art] = art; // Whole article number mapping

          let endIndex = art.indexOf('-'); // xx:ed article number mapping
          if (endIndex == -1) endIndex = art.length;
          const strippedArt = art.substring(0, endIndex - 2) + art.substring(endIndex);
          artMap[strippedArt] = art;
        }

        items.push(...whitelisted.map((wlArt) => artMap[wlArt] ?? artMap[wlArt.replace('xx', '')]).filter((art) => !!art));

        // Some articles only exist with -90 postfix's, fetch those separately
        const fixedColorWhitelist = whitelisted.filter((artnr) => !artnr.includes('xx'));
        const fixedColorArticles = await getArticles(fixedColorWhitelist);
        items.push(...Object.keys(fixedColorArticles));

        break;
    }
    items.push(
      ...calc_tile.value.getAccessoryOptions(key, {
        color: tile.color,
        finish: tile.coating,
        accessoryColor: accessoryColor,
      }),
    );
    acc[key] = items;
  }

  return acc;
};

/* 5. Extras watchers */
export default function () {
  // Update stored extras
  watch(
    () => selections.value.extra,
    (val) => {
      if (val) {
        const artnrs = [] as string[];
        for (const key in val) {
          for (const item of val[key] as unknown as {
            article: { article: Article };
            amount: { value: string };
            unit: { selected: string };
          }[]) {
            const amount = parseFloat(item.amount.value);
            if (item.article) {
              project.updateExtraArticle({
                artnr: item.article.article.ArtNr,
                amount: amount,
                total: (item.article.article.unit[item.unit.selected] ?? 1) * amount,
                unit: item.unit.selected,
              } as ExtraArticle);
              artnrs.push(item.article.article.ArtNr);
            }
          }
        }

        // project.cleanupExtraArticles(artnrs);
      }
    },
    { deep: true },
  );

  // Build extras
  const buildExtras = {
    abortController: null as AbortController | null,
    run: async function ([tile, angle, angle2, accessoryColor, baseroof, facadeHeight, loaded]: [
      Tile,
      number,
      number,
      AccessoryColor,
      Underlagstak,
      FacadeHeight,
      Promise<boolean>,
    ]) {
      // Abort the previous operation if it exists and start a new one
      this.abortController?.abort();
      this.abortController = new AbortController();
      const signal = this.abortController.signal;

      try {
        setLoading('extras', true);

        // Simulate an asynchronous operation
        const newValues = await new Promise<SectionValues>(async (resolve, reject) => {
          await loaded; // make sure variants are loaded

          const accessories = {} as { [key: string]: Article[] };

          const promises = [];
          const assortments = await getAssortments({
            tile,
            angle: project.isMansard(project.roofIndex) ? angle2 : angle,
            accessoryColor,
            baseroof,
            facadeHeight,
          });
          for (const key in assortments) {
            promises.push(
              getArticles(assortments[key].map((art) => (typeof art === 'string' ? art : art.artnr))).then((data) => {
                accessories[key] = Object.values(data);
              }),
            );
          }

          // wait for all promises
          await Promise.all(promises);

          const newValues = {} as SectionValues;
          for (const key in accessories) {
            newValues[key] = Object.values(accessories[key])
              .reduce((acc, cur) => {
                const prefix = getArtPrefix(cur.ArtNr); // Required for backwards compatibility
                const hash = getArtHash(cur.ArtNr);
                const existing = activeRoof.value.extras.articles?.[prefix] ?? activeRoof.value.extras.articles?.[hash];
                delete activeRoof.value.extras.articles?.[prefix];
                const artOptions = assortments[key].find((art) => typeof art !== 'string' && art.artnr == cur.ArtNr) as
                  | { artnr: string; sort: string }
                  | undefined;
                const item = {
                  article: {
                    article: cur,
                    total: 0,
                    weight: 0,
                  },
                  amount: {
                    value: existing?.amount?.toString() || '0',
                    type: 'number',
                    decimals: 0,
                  },
                  unit: {
                    article: cur,
                    selected: existing?.unit || cur.basicUnitOfMeasure || 'st',
                    options: Object.keys(cur?.unit ?? {}).map((unit) => ({
                      // label: convertUnit(unit),
                      value: unit,
                    })),
                  },
                  sort: artOptions?.sort || cur.ArtNr,
                };

                acc.push(item);
                return acc;
              }, [] as SectionValueItem[])
              .sort((a, b) => {
                const aName = a['sort'] as string;
                const bName = b['sort'] as string;
                if (aName < bName) {
                  return -1;
                }
                if (aName > bName) {
                  return 1;
                }
                return 0;
              });
          }

          resolve(newValues);
        });

        // Check if the operation has been aborted before setting the values
        if (!signal.aborted) {
          console.log('Updated assortments', newValues);
          selections.value.extra = newValues;
          setLoading('extras', false);
          this.abortController = null;
        }
      } catch (error: any) {
        if (error.name !== 'AbortError') {
          console.error('Error building extras', error);
        }
      }
    },
  };

  watch(
    () =>
      [
        activeRoof.value.tile,
        activeRoof.value.roof.dimensions.angle,
        activeRoof.value.roof.dimensions.angle2,
        activeRoof.value.accessories.color,
        activeRoof.value.roof.dimensions.baseRoof,
        activeRoof.value.roof.dimensions.facadeHeight,
        calc_tile.value.variantsLoaded,
      ] as [Tile, number, number, AccessoryColor, Underlagstak, FacadeHeight, Promise<boolean>],
    buildExtras.run.bind(buildExtras),
    { deep: true },
  );
}
