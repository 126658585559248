import type { ProjectData } from '@/project';

import { ref, watch, nextTick } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import _ from 'lodash';

// stores
import pinia from '@/store/store';
import { useProjectStore } from '@/store/project';
import { useNotificationStore } from '@/store/notification';

import { updateList } from '@/api/api';
import { reloadVariants } from '@/calculation/rooftile';
import { useArticleStore } from '@/store/article';

const saving = ref(false);

const project = useProjectStore(pinia);
const notification = useNotificationStore(pinia);
const articleStore = useArticleStore(pinia);

const { data } = storeToRefs(project);

// Saving and fetching data
export default function () {
  const router = useRouter();

  // Update project data
  const update = (...args: [ProjectData, string]) => {
    saving.value = true;
    updateList(...args)
      .then((list) => {
        if (project.id !== list.id) {
          project.setId(list.id);
          router.replace({ name: 'project', params: { id: list.id } });
        }
      })
      .catch(() => {
        // Warn about error
        notification.addNotification({
          type: 'error',
          message: 'error.saving_project',
        });
      })
      .finally(() => {
        nextTick(() => {
          saving.value = false;
        });
      });
  };
  const debounceSave = _.debounce(update, 250);

  // Saving
  watch(
    data,
    (val) => {
      // console.debug('Data changed', val, project.loading.fetch);
      // console.debug('Project', { ...project });
      if (project.loading.fetch || !project.id || !val) return;
      // console.debug('Saving project, id:', project.id);
      // remove 'editing' from data
      const data = _.cloneDeep(val);
      data.roofs.forEach((roof) => {
        roof.editing = {
          roof: false,
        };
        roof.extensions.forEach((ext) => {
          ext.editing = {
            roof: false,
          };
        });
      });
      data.market = project.getMarket;
      if (data) debounceSave(data, project.id);
    },
    { deep: true },
  );

  // Fetch data
  watch(() => project.id, project.fetchData);

  // Refetch articles on market change
  watch(
    () => project.market,
    () => {
      articleStore.clearCache();
      reloadVariants();
    },
  );
}
